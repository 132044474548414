.add__degree__specialization__form {
    padding: 5%;
    padding-top: 3%;
    padding-bottom: 2% !important;
}

.add__degree__specialization__form label.form-label {
    font-size: 13px;
    margin-bottom: 1px;
}

.add__degree__specialization__form .form-control {
    font-size: 13px;
    margin-bottom: 1px;
}

.add__degree__specialization__form Button {
    font-size: 13px;
    height: 2rem !important;
}

.add__degree__specialization__form .form-check{
    padding-top: 2%;
    padding-bottom: 0%;
    padding-left: 0%;
    font-size: 13px;
}