.add__student__excel__card {
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 1rem;
    margin-left: 25%;
    margin-top: 2%;
    /* height: 22rem; */
    min-width: 250px;
    background-image: linear-gradient(45deg,#43f5d1 0,#8951f3 100%);
    color: #fff !important;
}

.add__student__response {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 2%;
    margin-top: 3%;
    margin-bottom: 2%;
}

.add__student__excel__form Button {
    margin-left: 30%;
}

.typeSearchCollege .MuiButtonBase-root {
    margin-left: 0px;
}

.typeSearchCollege {
    background-color: whitesmoke;
    color: black;
}