hr {
    border: 1px solid teal;
}

.payment__details__form, .update__payment__details {
    width: 90%;
    margin-left: 1.5rem;
    margin-bottom: 2rem;
}

.update__payment__details  Button {
    /* float: right; */
    margin-left: 40%;
}