.MuiStepButton-root:focus {
    border: none;
    outline: none;
}

.MuiTab-root:focus {
    border: none;
    outline: none;
}

.MuiStepper-root {
    width: 100%;
    overflow-x: auto;
}

.post__job .form-label {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 1.5px;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #3f51b5 !important;
}

#employment__type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#employment__type .form-label {
    width: 25%;
}
#employment__type .form-control {
    width: 75%;
}


#job__role__autocomplete {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#job__role__autocomplete .form-label {
    width: 15%;
}
#job__role__autocomplete .MuiAutocomplete-root {
    width: 85%;
}


#job__vacancies {
    display: flex;
    flex-direction: row;
    align-items: center;   
    justify-content: center;
}
#job__vacancies .form-label {
    width: 25%;
}
#job__vacancies .form-control {
    width: 35%;
}
#job__vacancies #vacancy__message {
    width: 40%;
    margin-left: 8px;
    font-size: 13px;
    color: green;
}


#is__paid__row {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#is__paid__row .form-label {
    width: 16%;
    margin-bottom: 1rem;
}

#serviceBond {
    width: 50%;
}
#bondMonths {
    width: 90%;
}

#required__documents, #required__qualifications {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#required__documents .form-label, #required__qualifications .form-label {
    width: 30%;
}
#required__documents .form-group, #required__qualifications .form-group {
    width: 70%;
}

#job__lake {
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: center;
    cursor: pointer;
}
#job__lake .form-label {
    width: 30%;
}
#job__lake .form-group {
    width: 70%;
}


#hiring__location {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#hiring__location .form-label {
    width: 22%;
}
#hiring__location .form-group {
    width: 78%;
}


#eligible__years {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#eligible__years .form-label {
    width: 30%;
}
#eligible__years .form-group {
    width: 70%;
}


#equity__row {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#equity__row .form-label {
    width: 13%;
    margin-right: 1%;
}
#equity__row .form-check {
    width: 20%;
}
#equity__row .form-group {
    width: 33%;
    margin-bottom: 1rem;
}


#eligible__degrees {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#eligible__degrees .form-label {
    width: 16%;
}
#eligible__degrees .form-group {
    width: 84%;
}


#job__rounds {
    display: flex;
    flex-direction: row;
    align-items: center;     
}
#job__rounds .form-label {
    width: 16%;
}
#job__rounds .form-group {
    width: 84%;
}

#min__percentage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#min__percentage .form-label {
    width: 25%;
}
#min__percentage .form-control {
    width: 75%;
}

#work__experience {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#work__experience__label {
    width: 20%;
    font-size: 14px !important;
}
#work__experience .form-control {
    width: 80%;
}
#work__experience .form-label {
    font-size: 12px;
}


#offCampus__selectAllCourses .form-check {
    margin-left: 30%;
}


.college-list-box {
    width: 100%;
    height: auto;
    background: #000000;
    border-radius: 4px;
}
  
.college-item-box {
    width: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}  

.scrollY1 {
    overflow-y: scroll;
    height: 80vh;
    padding-left: 20px;
    padding-right: 20px;
}

.onCmpSingleClgDegrees {
    box-shadow: rgba(4, 26, 55, 0.16) 0px 1px 2.94px 0.06px;
    margin-bottom: 25px;
    border-radius: 5px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    transition: all 0.3s ease-in-out 0s;
}


.onCmpsAddCollegeButtonStyle {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: 152px;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(50, 130, 196);
    font-weight: 500;
    font-size: 18px;
    margin-left: 35%;
    border: none;
}


#offCampusSelectAllCoursesSpan .MuiFormControlLabel-label {
    width: 180px;
} 

#offCampusSelectAllSpecializationsSpan .MuiFormControlLabel-label {
    width: auto;
} 
#offCampusSingleCourses .MuiFormControlLabel-label {
    width: auto;
} 


.skill-chip {
    background: #fff;
    padding: 7px 20px;
    border: 2px solid #1d3354;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: 4px;
}

#college__count__div {
    border: 2px solid white;
    border-radius: 4px;
    padding: 10px;
    width: 30%;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 20px;
    justify-content: right;
    margin-left: auto;
    margin-right: 50px;
}

#select__all__filtered__colleges {
    padding: 10px;
    width: 70%;
    padding-top: 25px;
    padding-bottom: 12px;
    margin-bottom: 20px;
    justify-content: left;
}


#no__college__data__div {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%,
}

#no__college__data__div__div {
    font-size: 20px;
    font-weight: bold;
    margin-top: -40px;
    margin-bottom: 15px;
}

#no__college__data__image {
    height: 430px;
    margin-top: -75px;
}

#no__credits__data__div {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%,
}

#no__credits__data__div__div {
    font-size: 18px;
    font-weight: bold;
    margin-top: -40px;
    margin-bottom: 15px;
}

#no__credits__data__image {
    height: 330px;
    margin-top: -25px;
}

#contact__tech__team__data__div__div {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

#college__loader__box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:"5%";
}

#no__college__loader__box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}


#filter__colleges__div {
    width: 76%;
    margin-left: 12%;
    margin-bottom: 2rem;
}

#filter__college__card {
    background-color: #CDEDF6;
}

#filter__college__card__body {
    padding-bottom: 16px;
}

#filter__college__card__typography {
    align-items: center;
    font-size: 15px;
}


div.is-disabled {
    pointer-events: none;
    opacity: 0.7;
}