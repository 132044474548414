hr {
    border: 1px solid teal;
}

.select__college__form {
    width: 90%;
    margin-left: 1.5rem;
    margin-bottom: 2rem;
}

.modal {
    height: 75vh;
}