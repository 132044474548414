.personal__student__details .form-label{
    font-size: 12px;
    margin-bottom: 1px;
    font-style: italic;
    margin-left: 2px;
    color: green;
}

.save__student__changes {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: auto;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(50, 130, 196);
    font-weight: 500;
    font-size: 18px;
    margin-left: 38%;
    border: none;
}