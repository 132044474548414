.form-control-file {
  cursor: pointer;
}
.MuiCircularProgress-colorPrimary {
  color: rgb(0, 123, 255) !important;
}
button:focus {
  outline: unset !important;
}
.carousel {
  text-align: center;
}

.carousel__container {
  /* // max-width: 550px; */
  margin: auto;
  background-color: #fff;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 10%;
  padding-right: 10%;
  /* padding-bottom: 20%; */
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  /* width: calc(100% - 20px); */
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
  /* background-color: burlywood; */
}
.carousel__first-button,
.carousel__second-button {
  background-color: #2563eb;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 6px;
  margin: 5px;
  border: 2px solid #2563eb;
}

.first-button-icon,
.second-button-icon {
  color: #fff;
}

.carousel__second-button:disabled {
  background-color: gray;
  cursor: not-allowed;
  border: 2px solid #dbffef;
}
