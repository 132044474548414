*{padding: 0;
margin: 0;}
.container{
    padding: 0;
    margin: 0;
}
.cards{
    display: flex;
    margin-bottom: 2rem;
}
.card-item{
    margin-top: 2rem;
    margin-right: 2rem;
    width: 35%;
    padding: 1rem 2rem 1rem 1rem;
    height: auto;
    box-shadow: 1px 1px 5px 1px rgb(211, 210, 210);
    border-radius: 0.5rem;
}
.sub-menu{
    display: flex;
    justify-content: space-between;
}

.sub-menu>ul>li{
    list-style: none;
    line-height: 2rem;
}
.buttoon{
    width: 40%;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.5rem;
    border: none;
    transition: all 0.1s ease-in-out;
    border-radius: 0.3rem;
    align-items: start;
    cursor: pointer;
}
.buttoon:hover{
    background-color: rgb(226, 224, 224);
}
.left{
    color:#6C757D;
    padding: 0;
}

.side-section{
    width: 20%;
    height: 30rem;
    border: 1px solid black;
    border-radius: 0.5rem;
}