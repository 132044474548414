.MuiStepButton-root:focus {
    border: none;
    outline: none;
}

.MuiTab-root:focus {
    border: none;
    outline: none;
}

.MuiStepper-root {
    width: 100%;
    overflow-x: auto;
}

.job__track .form-label {
    font-size: 14px;
    font-style: italic;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #3f51b5 !important;
}

#stepper_active span {
    color: black;
    font-weight: 500;
}

#stepper_active circle {
    color: #3f51b5 !important;
}

#no-feedback {
    display: block;
    margin-left: 30%;
    font-size: 18px;
    color: red;
    font-family: cursive;
}