.add__company__form hr {
    border: 1px solid teal;
}

.add__company__form {
    width: 90%;
    margin-left: 1.5rem;
    margin-bottom: 2rem;
}

.add__company__form  p {
    font-weight: 300;
    font-size: 14px;
    color: gray;
}

.add__company__form  Button {
    /* float: right; */
    margin-left: 40%;
}

.add__company__form Form.Control {
    border: 0;
}

.add__company__form .form-label {
    font-size: 16px;
}

.form-check {
    padding-top: 1% !important;
    padding-right: 6%;
    padding-left: 0% !important;
    padding-bottom: 2%;
}

.editor {
    border: 1px solid rgb(215, 215, 215);
    margin-bottom: 5%;
}

.rdw-editor-toolbar *:hover {
    background-color: white;
    color: rgb(150, 146, 146);
}
.rdw-editor-main {
    padding: 0 20px 10px;
}
.typeSearchLocation .MuiButtonBase-root {
    margin-left: 0px;
}

.typeSearchPreferences .MuiButtonBase-root {
    margin-left: 0px;
}

.addPreferenceBtn {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: 170px;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(50, 130, 196);
    font-weight: 500;
    font-size: 18px;
    margin-left: 35%;
    border: none;
}

.delPreferenceBtn {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: 185px;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(196, 50, 62);
    font-weight: 500;
    font-size: 18px;
    margin-left: 35%;
    border: none;
}

.typeSearchAutoCompleteCoworking .MuiButtonBase-root {
    margin-left: 0px;
}
.typeSearchAutoCompleteIndustry .MuiButtonBase-root {
    margin-left: 0px;
}