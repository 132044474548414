/* Form.Control, Form.Group {
    width: 60% !important;
} */

hr {
    border: 1px solid teal;
}

.add__college__form {
    width: 90%;
    margin-left: 1.5rem;
    margin-bottom: 2rem;
}

.add__college__form  p {
    font-weight: 300;
    font-size: 14px;
    color: gray;
}

.add__college__form  Button {
    /* float: right; */
    margin-left: 40%;
}

.add__college__form Form.Control {
    border: 0;
}

.courses Button, .courses_list Button, .placement_session_list Button {
    /* margin-bottom: 1rem; */
    place-items: center;
    width: 10%;
    height: 2.5rem;
    margin-left: 1rem;
}

.add__college__form .form-label {
    font-size: 16px;
}

.form-check {
    padding-top: 1% !important;
    padding-right: 6%;
    padding-left: 0% !important;
    padding-bottom: 2%;
}

.typeSearchUniversity .MuiButtonBase-root {
    margin-left: 0px;
}

.typeSearchLocation .MuiButtonBase-root {
    margin-left: 0px;
}

.typeSearchCourses .MuiButtonBase-root {
    margin-left: 0px;
}

.typeSearchSegment .MuiButtonBase-root {
    margin-left: 0px;
}

.add__college__form .form-control-file {
    width: 21%;
}

.typeSearchPreferences .MuiButtonBase-root {
    margin-left: 0px;
}

.addPreferenceBtn {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: 170px;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(50, 130, 196);
    font-weight: 500;
    font-size: 18px;
    margin-left: 35%;
    border: none;
}

.delPreferenceBtn {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: 185px;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(196, 50, 62);
    font-weight: 500;
    font-size: 18px;
    margin-left: 35%;
    border: none;
}

.react-daterange-picker__calendar{
    z-index: 11 !important;
}

.passcode{
    text-transform: uppercase;
}