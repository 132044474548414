#sharer_category_add .form-label {
    font-size: 13px;
    font-style: italic;
    color: green;
}

#textColor.form-control {
    padding: 0;
}

#publish_notification {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
    width: auto;
    height: 36px;
    line-height: 0;
    padding: 0.35rem 1.25rem;
    margin-top: 1rem;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background: rgb(50, 130, 196);
    font-weight: 500;
    font-size: 18px;
    margin-left: 38%;
    border: none;
}

#push_notification_add {
    margin-top: 2rem;
    width: 90%;
    margin-left: 5%;
}

#push_notification_add .form-label {
    font-size: 13px;
    font-style: italic;
    color: green;
}