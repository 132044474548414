@media (max-width: 768px) {
  .sidebar {
    top: 10px;
  }
  .navbar-toggler {
    z-index: 140;
  }
}

.sidebar {
  display: flex;
  border-radius: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0px;
  width: 235px;
  margin-left: -235px;
  border: none;
  overflow-y: auto;
  background-color: #404040;
  bottom: 0;
  overflow-x: hidden;
  padding-bottom: 30px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  background-color: rgb(60, 63, 62);
  margin-top: 1.2em;
}

.sidebar__content > li {
  font-size: 13px;
  padding-bottom: 1.1rem;
}

.sidebar__content > li,
a {
  color: rgb(150, 146, 146);
  font-size: 12px;
}

.sidebar__content > li:hover,
a:hover {
  text-decoration: none;
  cursor: pointer;
  color: whitesmoke;
  background-color: rgb(53, 56, 55);
}

#items {
  list-style-type: none;
  margin-left: -25px;
  margin-right: -15px;
}

#items #items li {
  margin-left: -25px;
}

.MuiAccordion-root {
  background-color: rgb(60, 63, 62) !important;
}

/* .MuiSvgIcon-root {
    color: rgb(150, 146, 146) !important;
} */

::-webkit-scrollbar {
  width: 3px;
}

#coming__soon {
  font-size: 13px;
  font-style: italic;
  /* margin-left: -12px; */
  color: #16d0d0;
}
