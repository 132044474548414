*{
    padding:0;
    margin: 0;
    box-sizing: border-box;
    /* z-index: 9999; */
  }
  body{
    background: rgba(0,0,0,0.1);
    filter: blur(0.5);
  }
  .container1{
    border-radius: 0.5rem;
    height: auto;
    width: 40%;
    position: absolute;
    left: 50%;
    top: 6rem;
    padding: 1rem 2rem;
    transform: translate(-50%);
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0.1rem 0.1rem 0.5rem 0.2rem rgba(0,0,0,0.1);
    align-items: center;
  }
  .heading{
    align-self: flex-start;
    font-size: 2rem;
  }
  .sub-heading{
    align-self: flex-start;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: gray;

  }
  
  .container{
    margin-top: 2rem;
    height: 15rem;
    width: 95%;
    border: 1px dashed black;
    border-radius: 0.5rem;
    background-color: rgb(234, 240, 245);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #input-file{
    display: none;
  }
  .lable-input{
    border: 1px dotted rgb(127, 127, 238);
    background-color: rgb(34, 34, 248) ;
    border-radius: 0.5rem;
    color: white;
    padding: 1rem 2rem;
    margin: auto;
    max-width: 50%;
    white-space: nowrap;
    cursor: pointer;
  }
  .sample-file{
    color: blue;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
    margin-top: 0.5rem;
  }
  .sample-file:hover{
    background-color: transparent;
    color: blue;
  }
  .error{
    margin-top: 1rem;
    font-size: 1rem;
    align-self: flex-start;
    color: rgb(92, 91, 91);
  }
  .button{
    width: 100%;
    align-self: center;
    
  }
  .btnstyle{
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    width: 30%;
    border-radius: 0.5rem;
    margin: 1rem 0rem 1rem 5rem;
    white-space: nowrap;
  }
  .cancel{
    background-color: white;
    color: rgb(34, 34, 248);
    border-color: rgb(34, 34, 248);
  }
  .upload{
    background-color: rgb(34, 34, 248);
    color: white;
    border-color: rgb(34, 34, 248);
  }