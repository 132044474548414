.edit__college__details__form,
.edit__company__details__form
{
    width: 90%;
    margin-left: 1.25rem;
    margin-bottom: 1.5rem;
}

.edit__college__section,
.edit__company__section
{
    display: flex;
    flex-direction: column;
    margin-left: 1.25rem;
    margin-bottom: 1rem;
    /* background-color: aqua; */
    height: 90%;
    width: 90%;
    box-shadow: 3px 3px 4px 2px #9E9E9E;
}

.edit__general__details__form, .edit__specializations__form, .edit__preferences__form {
    padding: 5%;
    padding-top: 3%;
}
.edit__tpo__details__form, .add__tpo__details__form,
.edit__poc__details__form, .add__poc__details__form {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.edit__general__details__form label.form-label,
.edit__poc__details__form label.form-label,
.add__poc__details__form label.form-label,
.edit__tpo__details__form label.form-label,
.add__tpo__details__form label.form-label
{
    font-size: 13px;
    margin-bottom: 2px;
}

.edit__specializations__form label.form-label,
.edit__preferences__form label.form-label {
    font-size: 13px;
    margin-bottom: 1px;
}

.edit__specializations__form .form-control,
.edit__preferences__form .form-control{
    font-size: 13px;
    margin-bottom: 1px;
}

.edit__specializations__form Button,
.edit__preferences__form Button {
    font-size: 13px;
    height: 2rem !important;
}

.edit__college__section .form-check,
.edit__company__section .form-check
{
    padding-left: 6% !important;
    padding-top: 3%;
}

.passcode{
    text-transform: uppercase;
}