/* @media (min-width: 768px) {
    .login__form .form-control {
        width: 40%;
        border: none;
        border-bottom: 1px solid #ced4da;
    }
} */

.login {
    display: flex;
    height: 100vh;
    width: 100vw;
    font-family: sans-serif;
}

.login > img {
    display: flex;
    height: 100%;
    width: 100%;
}

.login__body {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 70%;
    height: 65%;
    margin-top: 10%;
    margin-left: 15%;
    margin-right: 20%;
    box-shadow: 3px 3px 3px 3px #9E9E9E;
}

.login__body__left {
    display: flex;
    flex-direction: column;
    width: 35%;
    background-image: linear-gradient(45deg,#00dcaf 0,#7536e6 100%);
    color: #fff !important;
}

.login__body__left > h3 {
    font-size: 1.75rem;
    margin-top: 50%;
    margin-left: 10%;
}
.login__body__left > small {
    font-size: 80%;
    font-weight: 400;
    margin-left: 10%;
}

.login__body__right {
    display: flex;
    flex-direction: column;
    width: 65%;
    background-color: white;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
}

.login__form {
    margin-top: 2rem;
}

.login__form .form-control {
    margin: 0 15%;
    width: 55%;
    border: none;
    border-bottom: 1px solid #ced4da;
    padding: 0.375em;
}

.login__form Button {
    margin: 0 15%;
}

.forgot__password {
    margin: 0 15%;
}

.forgot__password a {
    text-decoration: none;
    background-color: white;
    color: rgb(150, 146, 146);
}

.reset__form {
    margin-top: 2rem;
}

.reset__form .form-control {
    margin: 0 15%;
    width: 55%;
    border: none;
    border-bottom: 1px solid #ced4da;
    padding: 0.375em;
}

.reset__form Button {
    margin: 0 15%;
}