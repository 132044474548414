.reset__password__form {
    margin: 4em 1.5em 1em ;
}

#confirm-button {
    display:flex;
    justify-content:center;
    align-items:center;
}

.reset__password__form .form-control {
    margin: 0 15%;
    width: 65%;
    border: none;
    border-bottom: 1px solid #ced4da;
    padding: 0.375em;
}

.reset__password__form Button {
    margin: 0 15%;
}