.collegeDropdown{
    padding: 9px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.349);
    width: 100%;
}
.collegeDropdown2{
    padding: 9px;
    background-color: white;
    width: 95%;
}
.collegeList{
    list-style-type: none;
    width: 70%;
}
.collegeListItem{
    padding: 10px 10px 15px 10px;
}
.collegeListItem:nth-child(even) {
  background-color: #e4e2e283;
}
.viewBoxLink{
    padding: 8px 10px;
    color: purple;
    border: 1px solid rgba(128, 0, 128, 0.486);
    border-radius: 5px;
    font-weight: 500;
}
.viewBoxLink:hover{
    outline: 2px solid rgba(128, 0, 128, 0.356);
    background-color: white;
    color: black;
}