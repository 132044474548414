@media (min-width: 768px) {
  .admin__content {
    margin-left: 240px;
  }
}

.admin__right {
  overflow-x: hidden;
  overflow-y: hidden;
}

.nav {
  top: 0;
  left: -100%;
  height: 100vh;
  /* padding: 1rem 1rem 0; */
  background-color: #fff;
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: var(--z-fixed);
  /* transition: 0.9s; */
  /* transition: all 0.2s ease-in-out; */
  /* transition: transform 250ms; */
  /* transition: margin-left 0.5s; */

  transition: 0.3s;
  padding: 6px;
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none; /* For mozilla */
}

/* For Google Chrome and others */
.nav__container::-webkit-scrollbar {
  display: none;
}
/* ========== MEDIA QUERIES ==========*/
/* For small devices reduce search*/

@media screen and (min-width: 768px) {
  .nav {
    left: 0;

    /* padding: 1.2rem 1.5rem 0; */
    width: 67px; /* Reduced navbar */
  }

  /* Navbar expanded */
  .nav:hover {
    width: var(--nav-width);
    /* transform: translateX(5px); */
  }
}
