.skill-chip {
    background: #fff;
    padding: 7px 20px;
    border: 2px solid #1d3354;
    box-sizing: border-box;
    border-radius: 20px;
}

#view_job_ahref {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.viewjob_heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0;
}

.plan-section-pills {
    display: flex;
    margin-Left: 9.5rem;
}