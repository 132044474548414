*{
    scrollbar-width: none; 
    /* hide scrollbar in firefox */
}

.company__coworking__form,
.skill__type__form,
.skill__rating__form,
.company__domain__form,
.industry__form,
.page__type__form,
.add__year__form,
.education__skils__form,
.job__segment__form,
.job__role__group__form,
.job__role__form,
.job__round__form, 
.job__status__form
{
    margin: 2rem;
    width: auto;
}

#paid__services__form {
    margin: 1rem;
    width: auto;
}

.company__coworking__form h4,
.skill__type__form h4,
.skill__rating__form h4,
.company__domain__form h4,
.industry__form h4,
.page__type__form h4,
.add__year__form h4,
.education__skils__form h4,
.job__segment__form h4,
.job__role__group__form h4,
.job__role__form h4,
.job__round__form h4,
.job__status__form h4,
#paid__services__form h4
{
    text-align: center;
    margin-bottom: 2rem;
}

.company__coworking__form  Button,
.skill__rating__form Button,
.company__domain__form Button,
.education__skils__form Button,
.job__role__group__form Button,
.job__role__form Button,
.job__status__form Button
{
    margin-left: 45%;
}

.skill__type__form Button,
.industry__form Button, 
.page__type__form Button,
.add__year__form Button, 
.job__segment__form Button,
.job__round__form Button 
{
    place-items: center;
    width: 15%;
    height: 2.5rem;
    margin-left: 10%;
}

.change__password__form {
    margin: 5%;
    width: 80%;
}


#paid__services__form .form-label {
    font-size: 13px;
    font-style: italic;
    color: green;
}

#paid__services__form button {
    display: block;
    margin: auto;
}

.MuiAutocomplete-endAdornment {
    display: flex;
    flex-direction: row;
}

.upload_Pic{
    overflow: auto;
    margin-left: 3%;
    width: 94%;
}

.circularLoaderDiv{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(232, 239, 240, 0.1);
    width: 100vw;
    height: 100vh;
}

.circularLoader{
    position: relative;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
}