@media (max-width: 950px) {
    .add__student__job__excel__card h3{
        font-size: 1.5rem;
    }
}

.add__student__job__excel__card {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 1rem;
    margin-left: 30%;
    margin-top: 3%;
    height: 18rem;
    min-width: 200px;
    background-image: linear-gradient(45deg,#43f5d1 0,#8951f3 100%);
    color: #fff !important;
}

.add__student__job__response {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 2%;
    margin-top: 3%;
    margin-bottom: 2%;
}

.add__student__job__excel__form Button {
    display: block;
    margin: auto;
}

#info {
    margin-left: 1.8rem;
}