/* src/ImageSlider.css */
.image-slider {
  width: 80%;
  margin: auto;
  text-align: center;
}

.image-slider .slick-dots {
  bottom: -30px;
}

.image-slider img {
  max-width: 100%;
  height: auto;
  margin: auto;
}

.image-slider a {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #007bff;
}
