.account__manager__form
{
    margin: 2rem;
    width: auto;
}

.account__manager__form h4
{
    text-align: center;
    margin-bottom: 2rem;
}

.account__manager__form  Button
{
    margin-left: 45%;
}

.typeSearchManagerName .MuiButtonBase-root {
    margin-left: 0px;
}
.typeSearchAutoCompleteCollege .MuiButtonBase-root {
    margin-left: 0px;
}
.typeSearchAutoCompleteCompany .MuiButtonBase-root {
    margin-left: 0px;
}