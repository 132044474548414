@media (min-width: 768px) {
  #brand_logo {
    margin-left: 250px;
  }
}

.navbar {
  width: 100vw;
  margin: 0;
  padding: 0;
}

.ml-auto {
  margin-right: 2.5rem;
}

#basic-nav-dropdown:hover {
  background-color: #f8f9fa !important;
}
