#select__user__type {
    margin-top: 5px;
    margin-left: 1rem;
}

#select__user__type .form-label {
    font-size: 13px;
    margin-bottom: 0;
    font-style: italic;
    color: green;
}

#add__credits {
    font-size: 28px;
}

#credit_info {
    margin-left: 1rem;
}